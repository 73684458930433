import CloseIcon from '@mui/icons-material/Close';
import { FormControl, FormHelperText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { castToType } from '../utils/misc.js';

const useStyles = makeStyles(() => ({
  icon: {
    right: 30,
  },
  selectSelect: {
    paddingLeft: '0px',
  },
  root: {},
}));

const CustomSelect = (props) => {
  const classes = useStyles();

  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;

    let e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };

    if (typeof value === 'object' && !Array.isArray(value)) {
      e = { target: { name: name, value: value.value, checked: undefined } };
    }

    props.onChange(e);
  };

  return (
    <>
      <FormControl fullWidth className={classes.root} variant={'standard'} error={props.error}>
        <InputLabel id={`${props.name}-label`} required={props.required}>
          {props.label}
        </InputLabel>
        <Select
          MenuProps={{
            style: {
              maxHeight: '500px',
            },
          }}
          data-test-select={props['data-test'] || props.name}
          name={props.name}
          value={props.value?.value ? props.value.value : props.value}
          labelId={`${props.name}-label`}
          label={props.label}
          disabled={Boolean(props.disabled)}
          onChange={(e) => {
            handleInputChangeCasted(e, props.propType);
          }}
          {...(props.clearFieldIcon && props.value !== '' && props.value !== null
            ? { classes: { icon: classes.icon, select: classes.selectSelect } }
            : {})}
          endAdornment={
            Boolean(props.clearFieldIcon) &&
            props.value !== '' &&
            props.value !== null && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="reset"
                  onClick={() => props.onChange({ target: { name: props.name, value: '' } })}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {props.list?.map((item, index) => (
            <MenuItem
              data-test-select-value={item.value}
              style={{ height: '48px' }}
              disabled={item.disabled}
              value={item.value}
              key={index}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText id="my-helper-text">{props.helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;
