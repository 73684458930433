import { useApolloClient } from '@apollo/client';
import { Download, Email, Key, WhatsApp } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, CircularProgress, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import { clsx } from 'clsx';
import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { msg } from '../../../constants/messages';
import { RootState } from '../../../store';
import { setSettings } from '../../../store/settingsSlice';
import { downloadNotificationsByUserId } from '../../../utils/downloadNotifications';
import { CHANNELS } from '../../../utils/notificationProfiles';
import { useAuth } from '../../../utils/useAuth';
import EditNotificationsSettingsModal from '../../EditNotificationsSettingsModal';
import EditProgramSettingsModal from '../../EditProgramSettingsModal';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import BellPlus from '../../icons/bellPlus';
import ChangePassword from '../ChangePassword';
import DateModal from '../DateModal';
import st from './style.module.css';

const ProfileModalNew = () => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);
  const client = useApolloClient();
  const { loadUser, isLoadingUser } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints?.down('sm'));

  const openProfileModal = () => {
    setOpen(true);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (!(anchorRef.current && anchorRef.current.contains(event.target))) {
      setOpen(false);
    }
  };

  const user = useSelector((state: RootState) => state?.user);
  const history = useCustomNavigate();

  const handleLogOutClick = async () => {
    await client.clearStore().then(() => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenId');
      dispatch(setSettings({ isDrawerOpen: false }));
      history('/login');
    });
    // logout();
  };

  const getProfileByChannel = (channel: string): any => {
    if (!user) {
      return null;
    }

    const profiles =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      user?.userProfiles?.[0]?.profile?.objectsToObjectsByObject1Id?.map((item) => item?.object2) || [];

    return profiles.find((item) => item?.deliveryChannel === channel);
  };

  const items = [
    {
      isShown: true,
      isDisabled: true,
      icon: <SettingsIcon />,
      text: msg.profileModal.editProfile,
      handleAction: () => {},
    },
    {
      isShown: true,
      icon: <Key />,
      text: 'Change password',
      handleAction: () => {
        setOpen(false);
        ChangePassword()
          .then()
          .catch(() => {});
      },
      testAttribute: 'changePassword',
    },

    {
      isShown: true,
      icon: <EditIcon />,
      text: msg.profileModal.programSettings,
      handleAction: () => {
        setOpen(false);
        EditProgramSettingsModal()
          .then()
          .catch(() => {});
      },
      testAttribute: 'programSettings',
    },

    {
      isShown: getProfileByChannel(CHANNELS.IN_APP),
      icon: <BellPlus />,
      text: 'Notification settings',
      handleAction: () => {
        setOpen(false);
        EditNotificationsSettingsModal({
          profile: getProfileByChannel(CHANNELS.IN_APP),
        })
          .then()
          .catch(() => {});
      },
      testAttribute: 'notificationsSettingsInApp',
    },
    {
      isShown: getProfileByChannel(CHANNELS.SMS),
      icon: <AnnouncementIcon />,
      text: 'SMS (Twilio)',
      handleAction: () => {
        setOpen(false);
        EditNotificationsSettingsModal({
          profile: getProfileByChannel(CHANNELS.SMS),
        })
          .then()
          .catch(() => {});
      },
      testAttribute: 'notificationsSettingsSms',
    },
    {
      isShown: getProfileByChannel(CHANNELS.WHATSAPP),
      icon: <WhatsApp />,
      text: 'WhatsApp (Twilio)',
      handleAction: () => {
        setOpen(false);
        EditNotificationsSettingsModal({
          profile: getProfileByChannel(CHANNELS.WHATSAPP),
        })
          .then()
          .catch(() => {});
      },
      testAttribute: 'notificationsSettingsSmsWhatsApp',
    },
    {
      isShown: getProfileByChannel(CHANNELS.EMAIL),
      icon: <Email />,
      text: 'E-mail notifications',
      handleAction: () => {
        setOpen(false);
        EditNotificationsSettingsModal({
          profile: getProfileByChannel(CHANNELS.EMAIL),
        })
          .then()
          .catch(() => {});
      },
      testAttribute: 'notificationsSettingsEmail', // ?
    },
    {
      isShown: true,
      icon: <Download />,
      text: 'Download notifications',
      handleAction: () => {
        setOpen(false);
        DateModal({
          downloadHistory: downloadNotificationsByUserId,
          downloadIds: [user.id],
          title: 'Download notifications',
        })
          .then()
          .catch(() => {});
      },
      testAttribute: 'notificationsDownload',
    },
    {
      isShown: true,
      icon: <ExitToAppIcon />,
      text: msg.profileModal.logOut,
      handleAction: handleLogOutClick,
      testAttribute: 'logout',
    },
  ];

  if (!user)
    return (
      <Fab size="small" color="primary" className={st.offset} aria-label="profile">
        UN
      </Fab>
    );

  return (
    <Fragment>
      {!isSm && (
        <>
          <Fab
            data-test="userMenu"
            ref={anchorRef}
            color="primary"
            size="small"
            className={st.offset}
            aria-label="profile"
            onClick={() => {
              if (!open) {
                loadUser()
                  .then(() => {
                    openProfileModal();
                  })
                  .catch(() => {});
              } else {
                openProfileModal();
              }
            }}
          >
            {isLoadingUser && (
              <Box className={st.loadingContainer}>
                <CircularProgress
                  thickness={2}
                  color="inherit"
                  size={40}
                  value={user?.mName?.[0] || user.login[0]}
                ></CircularProgress>
              </Box>
            )}
            {user?.mName?.[0] || user.login[0]}
          </Fab>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
            disablePortal={true}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={8}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <div>
                      <Grid container className={st.info}>
                        <Grid xs={2} alignItems="center" container item>
                          <Avatar sizes="small" className={st.avatar}>
                            {user?.mName?.[0] || user.login[0]}
                          </Avatar>
                        </Grid>
                        <Grid xs={10} item>
                          <Grid xs={12} item container justifyContent="flex-start">
                            <Typography variant="h6" className={st.name}>
                              {String(user?.login || user?.mName)}
                            </Typography>
                          </Grid>
                          <Grid xs={12} item>
                            <Typography variant="body1" className={st.email}>
                              {String(user?.mEmail)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container direction="column">
                        <Grid item>
                          <List>
                            {items.map(({ isShown, isDisabled, icon, text, handleAction, testAttribute }, index) =>
                              isShown ? (
                                <ListItemButton
                                  key={index}
                                  data-test-user-menu={testAttribute}
                                  disabled={isDisabled}
                                  onClick={handleAction}
                                >
                                  <ListItemIcon className={st.listItemIcon}>{icon}</ListItemIcon>
                                  <ListItemText primary={<Typography variant="subtitle2">{text}</Typography>} />
                                </ListItemButton>
                              ) : null
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {isSm && (
        <>
          <ListItem onClick={() => setOpen(true)}>
            <ListItemIcon className={st.listItemIcon}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="subtitle2">{msg.mainSideMenu.account}</Typography>} />
          </ListItem>
          <Dialog
            fullScreen={true}
            open={open}
            className={clsx(st.modalDialog, st.pointerNone)}
            BackdropProps={{
              classes: {
                root: st.modalAppBackDrop,
              },
            }}
            PaperProps={{
              classes: { root: st.modalPaper },
              className: st.pointerAuto,
            }}
            hideBackdrop={false}
            disableEnforceFocus={true}
          >
            <DialogContent className={st.modalContent}>
              <Grid container direction="column" justifyContent="space-between" className={st.hFull}>
                <div>
                  <Grid container className={st.info}>
                    <Grid xs={2} alignItems="center" container item>
                      <Avatar sizes="small" className={st.avatar}>
                        {String(user?.mName?.[0] || user.login[0])}
                      </Avatar>
                    </Grid>
                    <Grid xs={10} item>
                      <Grid xs={12} item container justifyContent="flex-start">
                        <Typography variant="h6" className={st.name}>
                          {String(user?.mName || user.login)}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Typography variant="body1" className={st.email}>
                          {String(user.mEmail)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container direction="column">
                    <Grid item>
                      <List>
                        {items.map(({ isShown, isDisabled, icon, text, handleAction, testAttribute }, index) =>
                          isShown ? (
                            <ListItemButton
                              key={index}
                              data-test-user-menu={testAttribute}
                              disabled={isDisabled}
                              onClick={handleAction}
                            >
                              <ListItemIcon className={st.listItemIcon}>{icon}</ListItemIcon>
                              <ListItemText primary={<Typography variant="subtitle2">{text}</Typography>} />
                            </ListItemButton>
                          ) : null
                        )}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Fragment>
  );
};

export default ProfileModalNew;
